// @ts-check
import React from "react";

const FormGroup = ({label_for, label = "", required = false, children, special_class=""}) => {
    return <div className={special_class}>    
        <label className={required ? "required" : ""} htmlFor={label_for}>
            {label}
            {children}
        </label>
    </div>
}

export default FormGroup;