// @ts-check
import React, { Component, useState, cloneElement, useEffect} from "react"
import { createRoot } from 'react-dom/client';
import { flushSync } from 'react-dom';
import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useTitle } from "./AdminRoot.js";
import { json, Link, NavLink, redirect, useLoaderData, useNavigate, useSearchParams } from "react-router-dom";

export async function userListLoader({request}) {
    const login_url = "/login?next=" + encodeURIComponent(request.url);
    const url = new URL(request.url);
    const filter = url.searchParams.get("filter") ?? "all";

    let res = await fetch(__API_URL__ + "/webapi/admin/users/" + filter);
    if (res.status === 401){
        return redirect(login_url);
    } 
    let d = await res.json();
    let i = 1;
    for (const user of d) {
        if (user.owner === user.email){
            user.owner = "-"
        }
        user.sortindex = i;
        i++;
    }
    return json(d);
}

function serializeCellValue(value) {
    if (typeof value === 'string') {
        const formattedValue = value.replace(/"/g, '""');
        return formattedValue.includes(',') ? `"${formattedValue}"` : formattedValue;
    }
    return value;
}

function downloadFile(fileName, data) {
    const downloadLink = document.createElement('a');
    downloadLink.download = fileName;
    const url = URL.createObjectURL(data);
    downloadLink.href = url;
    downloadLink.click();
    URL.revokeObjectURL(url);
}

function BooleanFormatter({ theValue }) {
    return <span>{theValue ? <>&#x2714;</> : <>&#x25A1;</>}</span> ;
}

  
const dateFormatter = new Intl.DateTimeFormat(navigator.language);

function TimestampFormatter({ timestamp }) {
    return <>{timestamp == null ? "-" : dateFormatter.format(new Date(timestamp))}</>;
}

export function UserList({})
{
    const navigate = useNavigate();
    const users = useLoaderData();

    const [sortColumns, setSortColumns] = useState([]);

    useTitle("Registrierte Benutzer");

    const [searchParams, setSearchParams] = useSearchParams();
    
    let searchTerm = "";
    let filter = "all";
    for (const [key, value] of searchParams) {
        if (key === "term"){
            searchTerm = value;
        }
        if (key === "filter"){
            filter = value;
        }
    }

    function getComparator(sortColumn) {
        switch (sortColumn) {
            case 'sortindex':
                return (a, b) => a[sortColumn] - b[sortColumn];
            case 'email':
            case 'name':
            case 'owner':
            case 'license':
                return (a, b) => a[sortColumn].localeCompare(b[sortColumn]);
            case 'has_completed_install':
            case 'free_account':
                return (a, b) => {
                return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1;
                };
            case 'created':
            case 'expiration':
                return (a, b) => {
                    return new Date(a[sortColumn]) - new Date(b[sortColumn]);
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    }

    function sortRows(rows, sortColumns){
        if (sortColumns.length === 0){
            return rows;
        }

        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = getComparator(sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }

    async function exportToCsv(fileName) {
        const { head, body, foot } = await getGridContent();
        const content = [...head, ...body, ...foot]
            .map((cells) => cells.map(serializeCellValue).join(';'))
            .join('\n');
        downloadFile(fileName, new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]),
                                        content], { type: 'text/csv;charset=utf-8;' }));
    }
    
    
    async function getGridContent() {
        const grid = document.createElement('div');
        const root = createRoot(grid);
        flushSync(() => {
            root.render(CreateGridElement(
                (args, e) => false,
                createColumns(true, false),
                sortColumns,
                newSort => setSortColumns(newSort),
                sortedAndFilteredRows,
                summaryRows,
                false
                ));
        });
        return {
            head: getRows('.rdg-header-row'),
            body: getRows('.rdg-row:not(.rdg-summary-row)'),
            foot: getRows('.rdg-summary-row')
        };
    
        function getRows(selector) {
            return Array.from(grid.querySelectorAll(selector)).map((gridRow) => {
                return Array.from(gridRow.querySelectorAll('.rdg-cell')).map(
                (gridCell) => gridCell.innerText
                );
            });
        }
    }

    if (users === null){
        return null;
    }

    function createColumns(exporting, paying_only){
        let columns = [
            { key: 'sortindex', name: '#', resizable: true, width: 0 },
            { key: 'email', name: 'E-Mail', resizable: true, width: 270,       
            renderSummaryCell({ row }) {
                return <>{row.totalCount} Benutzer in dieser Ansicht</>;
                },
                },
            { key: 'name', name: 'Name', width: 150, resizable: true },
            { key: 'created', name: 'Erstellt', resizable: true,
            renderCell({row, onRowChange, tabIndex}){
                return (<TimestampFormatter timestamp={row.created} />)
            }
            },
            { key: 'expiration', name: 'Ablauf', resizable: true,
            renderCell({row, onRowChange, tabIndex}){
            return (<TimestampFormatter timestamp={row.expiration} />)
            } },
            { key: 'license', name: 'Lizenztyp', resizable: true },
            { key: 'has_completed_install', name: 'Voll. Install.', 
            renderCell({row, onRowChange, tabIndex}){
                return <BooleanFormatter theValue={row.has_completed_install} />;
                },
                resizable: true },
        ];
        if (!paying_only){
            columns.push({ key: 'free_account', name: 'Kostenlos', 
                renderCell({row, onRowChange, tabIndex}){
                    return <BooleanFormatter theValue={row.free_account} />;
                    },
                resizable: true});
        }
        columns.push({ key: 'owner', name: 'Eigentümer', width: 150, resizable: true });
        if (!exporting){
            columns.push({ key: 'details', name: 'Öffnen', width: 50,  resizable: true, sortable: false,
                renderCell({row, onRowChange, tabIndex}){
                    if (exporting || row.id.toString().startsWith("license_")){
                        return <Link to={"/admin/license/" + row.id.toString().substring("license_".length)}><FontAwesomeIcon icon={faArrowRight} /></Link>;
                    }
                    return <Link to={"/account/edit/" + row.id}><FontAwesomeIcon icon={faArrowRight} /></Link>;
                } });
        }
        return columns;
    }

    const sortedRows = sortRows(users, sortColumns);

    const searchTermParts = searchTerm.toLowerCase().split(" ");

    const sortedAndFilteredRows = [...sortedRows].filter(row => {
            return searchTermParts.every((searchTermPart) => {
                if (row.email.toLowerCase().includes(searchTermPart)){
                    return true;
                }
                if (row.name.toLowerCase().includes(searchTermPart)){
                    return true;
                }
                if (row.owner.toLowerCase().includes(searchTermPart)){
                    return true;
                }
                return false;
            });    
        }
    );

    const summaryRows = [
        {
            id: 'total_0',
            totalCount: sortedAndFilteredRows.length,
        }
    ];

    const gridElement = CreateGridElement(
        (args, e) => {
            if (!args.row.id.toString().startsWith("license_")){
                navigate('/account/edit/' + args.row.id);
            }
            else{
                navigate("/admin/license/" + args.row.id.toString().substring("license_".length));
            }
        }, 
        createColumns(false, filter === "paying"),
        sortColumns,
        newSort => setSortColumns(newSort),
        sortedAndFilteredRows,
        summaryRows,
        true
        );

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        setMinimalSearchParams({term: value, filter: filter});
    }

    const setMinimalSearchParams = ({term, filter}) => {
        let new_searchParams = {};
        if (filter !== "all"){
            new_searchParams.filter = filter;
        }
        if (term !== ""){
            new_searchParams.term = term;
        }
        setSearchParams(new_searchParams);
    }

    const adminAddNewUserClicked = ({}) => {
        navigate("/admin/users/new");
    };

    const createLinkTarget = (filter) => {
        if (filter === ""){
            return searchTerm !== "" ? `?term=${searchTerm}` : "";
        }
        else{
            return searchTerm !== "" ? `?filter=${filter}&term=${searchTerm}` : `?filter=${filter}`;
        }
    }

    return (
        <div id="userlist-datagrid">
        <div>
            <label htmlFor="list-filter">Suche:
            <input type="search" placeholder="Benutzer filtern..." id="list-filter" name="list-filter" value={searchTerm}  role="search" onChange={handleSearchChange} />
            </label>
            <NavLink className={({isActive}) => (isActive && filter === 'all') ? 'miniNavLink active' : 'miniNavLink'} to={createLinkTarget("")}>Alle Benutzer (außer Spam)</NavLink>
             &nbsp;| <NavLink className={({isActive}) => (isActive && filter === 'licensed') ? 'miniNavLink active' : 'miniNavLink'} to={createLinkTarget("licensed")}>Aktuelle Lizenzbesitzer</NavLink>
             &nbsp;| <NavLink className={({isActive}) => (isActive && filter === 'paying') ? 'miniNavLink active' : 'miniNavLink'} to={createLinkTarget("paying")}>Zahlende Kunden</NavLink>
             &nbsp;| <NavLink className={({isActive}) => (isActive && filter === 'spam') ? 'miniNavLink active' : 'miniNavLink'} to={createLinkTarget("spam")}>Spam</NavLink>
            <ExportButton onExport={() => exportToCsv('Benutzer.csv')}>
                CSV exportieren
            </ExportButton>
            <button
      type="button"
      className="small button alt csvexportbutton"
      onClick={adminAddNewUserClicked}>Neuer Benutzer</button>
        </div>
        {gridElement}
        </div>
    );
}

function CreateGridElement(doubleClick, columns, sortColumns, sortChanged, sortedRows, summaryRows, enableVirtualization){
    return <DataGrid
            onCellDoubleClick={doubleClick}
            columns={columns}
            defaultColumnOptions={{
                sortable: true,
                resizable: true
            }}
            sortColumns={sortColumns}
            onSortColumnsChange={sortChanged}
            rows={sortedRows}
            bottomSummaryRows={summaryRows}
            enableVirtualization={enableVirtualization} />
}

function ExportButton({ onExport, children }) {
  return (
    <>
    <button
      type="button"
      className="small button alt csvexportbutton"
      onClick={async () => {
        await onExport();
      }}
    >
      {children}
    </button>
    </>
  );
}