// @ts-check

export class AppInstallerParser{

    url = null;

    parseAppInstaller(xmlStr){
        const parser = new DOMParser();
        const doc = parser.parseFromString(xmlStr, "application/xml");
        const version = doc.querySelector("AppInstaller").getAttribute("Version");
        return version;
    }

    loadVersion(){
        return fetch(this.url)
        .then(response => response.text())
        .then(response => this.parseAppInstaller(response));
    }

    constructor(url){
        this.url = url;
    }
}