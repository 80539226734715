

/**
 * @param {string} url
 * @param {AbortSignal|null} abort_signal
 * @returns {Promise}
 */
export function fetchUser(userId, abort_signal){
    let url = "/webapi/userinfo";
    if (userId !== undefined && userId !== null){
        url = url + `/${userId}`
    }
    return fetch(__API_URL__ + url,{
        signal: abort_signal
    })
    .then(request => {
        if (request.status === 401){
            throw new Error("not logged in");
        }
        return request;
   })
   .then(data => data.json());
}