// @ts-check
import React from "react";
import { MenuRouted } from "./MenuRouted.js";
import { Await, defer, json, Navigate, Outlet, redirect, useAsyncError, useLoaderData, useNavigate } from "react-router-dom";

export async function indexPageLoader(){
    const menuPromise = fetch(__API_URL__ + '/webapi/menuinfo')
        .then(result => {
            if (result.redirected && result.url.includes("/account/terms-consent")) {
                throw "terms-consent";
            }
            else{
                return result;
            }
        })
        .then(result => result.json())
        .then(
            data => ({
            menuContent: decodeURIComponent(data.menu),
            isAdmin: data.isAdmin,
            isTrial: data.isTrial,
            isViewer: data.isViewer,
            daysValidity: data.daysValidity,
            signedIn: data.signedIn,
            logoutUrl: data.logoutUrl
        }))
        

    return defer({menuData: menuPromise});
}

function MenuError(){
    const error = useAsyncError();
    if (error === "terms-consent" && !window.location.href.includes("/account/terms-consent/")){
        return <Navigate to={"/account/terms-consent/"+ encodeURIComponent(window.location.href)} />
    }
    if (error === "terms-consent"){
        return null;
    }
    console.log("MenuError", error);
    return <p>Fehler beim Menü laden</p>
}

export function IndexPage () {
    const loaderData = useLoaderData();
    return (<>
    <header id="header">
        <h1><a href={__API_URL__} className="icon solid">
            <img className="textLogo" width="150"
             height="35" alt="telani"
            src="/img/logo-text.svg" />
        </a></h1>
        <nav id="nav">
            <ul id="react-menu">
            <React.Suspense
        fallback={null}
      >
        <Await
          resolve={loaderData.menuData}
          errorElement={<MenuError />}
        >
          {(menuData) => (
                <MenuRouted menuData={menuData} />
          )}
          </Await>
          </React.Suspense>
            </ul>
        </nav>
    </header>
    <div id="page-wrapper">  
        <Outlet />
    </div>
    <footer id="footer">
        <ul className="icons">
            <li><a href="https://facebook.com/telaniapp" rel="noopener" title="Facebook" className="icon">
                <i className="fa-brands fa-facebook-f"></i>
            <span className="label">Facebook</span></a></li>

            <li><a href="https://www.linkedin.com/company/telani-app/" rel="noopener" title="LinkedIn" className="icon">
                <i className="fa-brands fa-linkedin-in"></i>
            <span className="label">LinkedIn</span></a></li>
        </ul>
        <div className="copyright">
            <p>&copy; 2015 - {new Date().getFullYear()} <a href="https://gb-t.de" className="impressum">gb&amp;t Geb&auml;udebestand &amp; Technik GmbH</a></p>
            <ul className="legal-links">
                <li><a href="/impressum" className="impressum">Impressum</a></li>
                <li><a href="/bedingungen" className="impressum">AGB</a></li>
                <li><a href="/datenschutz" className="impressum">Datenschutzerklärung</a></li>
            </ul>
        </div>
    </footer></>);
}