import React, { useEffect, useState } from "react";

export async function revokeInvitationAction({params, request}){
    if (request.method !== "POST"){
        console.error("Wrong method");
    }
    console.log("REVOKING Invitation");

    const res = fetch(__API_URL__ + "/webapi/revoke-invite/" + params.invitation, {
        method: "POST",
        signal: request.signal
    });    
    return res;
}