// @ts-check
import React, { useEffect, useState } from "react"
import { GoToLogin } from "./react-entry.js";
import { Form, json, redirect, useActionData, useLoaderData, useNavigate, useParams } from "react-router-dom";

export async function TermsConsentAction({request, params}){
    if (request.method !== "POST"){
        console.error("Wrong method");
    }
    const formData = await request.formData();

    const privacy = formData.get("privacy");
    const terms = formData.get("terms");
    const token = formData.get("token");
    const next = formData.get("next");

    if (privacy && terms){
        const response = await fetch(__API_URL__ + `/webapi/terms-consent?token=${token}`, {
            method: "POST"
        });
        const data = await response.json();
        if (data.success){
            if (next !== null && next !== ""){
                return redirect(next);
            }
            else {
                return redirect("/account");
            }
        }
        else if (data.wrong_token){
            return {error: true, message: data.message};
        }
        else{
            return {error: true, message: "Bitte versuchen Sie es erneut."};
        }
    }
    else{
        return {error: true, message: "Bitte akzeptieren Sie unsere Datenschutzerklärung und unsere AGB."};
    }
}


export async function TermsConsentLoader(){
    const response = await fetch(__API_URL__ + '/webapi/termsConsentToken');
    const data = await response.json();
    return json({token: data["token"]});
}

export function TermsConsent() {

    const {token} = useLoaderData();

    let message = null;
    const actionData = useActionData();
    if (actionData !== undefined && actionData !== null){
        message = actionData.message;
    }

    const { next } = useParams();

    const next_render = (next !== undefined && next !== null && next !== "") ? new URL(decodeURIComponent(next)).toString() : "";

    let message_block = message !== null ? <div className="alert alert-danger">{message}</div> : null;

    return <>
        <p>
            Seit Ihrem letzten Login haben sich unsere <a href='/bedingungen'>Allgemeinen Geschäftsbedingungen</a> und unsere <a href='/datenschutz'>Datenschutzerklärung</a> geändert. Bitte nehmen Sie sich einen Moment Zeit und überprüfen diese.
        </p>
        <p>
            Bei Fragen können Sie sich per E-Mail an uns wenden: <a href="mailto:support@telani.net">support@telani.net</a>
        </p>
        <Form method="post">
            <input type="hidden" name="token" value={token} />
            <input type="hidden" name="next" value={next_render} />
            <div className="form-group">
                <div>
                    <div className="checkbox">
                        <label className="required" htmlFor="consent_update_form_privacy_policy_accepted"> 
                            <input type="checkbox"
                                id="consent_update_form_privacy_policy_accepted"
                                name="privacy" required
                                value="1" />
                            <span>
                            Ich habe die <a href='/datenschutz'>Datenschutzerklärung</a> gelesen und akzeptiere diese.
                            </span>
                        </label>
                    </div>
                </div>
            </div>

            <div className="form-group">
                <div>
                    <div className="checkbox">
                        <label className="required" htmlFor="consent_update_form_terms_accepted"> 
                        <input type="checkbox"
                            id="consent_update_form_terms_accepted" 
                            name="terms"
                            required value="1" />
                            <span>
                            Ich habe die <a href='/bedingungen'>Allgemeinen Geschäftsbedingungen</a> gelesen und akzeptiere diese.
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            {message_block}
            <div className="form-group">
                <input type="submit" value="Weiter" className="btn btn-primary pull-right" />
            </div>
        </Form>
    </>
}