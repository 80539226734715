// @ts-check
import React, { useState, useEffect} from "react";
import { Form, json, Link, Navigate, redirect, useActionData, useNavigation, useSearchParams } from "react-router-dom"; 

export async function loginAction({params, request}){
    console.log("using LoginAction");
    if (request.method !== "POST"){
        console.error("Wrong method");
    }
    const formData = await request.formData();
    const email = formData.get("email");
    const password = formData.get("password");
    if (email !== "" && password !== ""){
        const result = await fetch(__API_URL__ + '/check-login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: email,
                password: password
            })
        });
        const data = await result.json();
        if (data.success){
            console.log("Login was successful");
            const next = formData.get("next");
            if (formData.get("next") !== ""){
                return redirect(next);
            }
            else{
                return redirect("/account");
            }
        }
        else{
            return json({message: data.error});
        }
    }
}

export function urlToRelative(abs) {
    if (abs.startsWith("/")){
        return abs;
    }
    const url = new URL(abs);
    const rel = url.toString().substring(url.origin.length);
    return rel;
  };

export function Login(){

    let [searchParams, setSearchParams] = useSearchParams();
    let next = "";
    for (const [key, value] of searchParams) {
        if (key === "next"){
            next = urlToRelative(decodeURIComponent(value));
        }
    }

    const {state: loadingState} = useNavigation();

    const currentlyLoading = loadingState === "submitting" || loadingState === "loading";

   let message = null;
    const actionData = useActionData();
    if (actionData !== undefined && actionData !== null && actionData.message !== undefined){
        message = actionData.message;
    }

    let message_block = message !== null ? <div className="formerror">{message}</div> : null;

    return <Form method="post">
        <div className="form-group">
            <label htmlFor="username">E-Mail Adresse
                <input type="email" tabIndex={1}
                    disabled={currentlyLoading}
                 autoComplete="email" className="form-control" id="username" 
                name="email" required autoFocus />
                <span className="smallNote">Noch kein Konto? <Link tabIndex={4} to="/register">Jetzt registrieren</Link></span>
            </label>
        </div>
        <div className="form-group">
            <label htmlFor="password">Passwort 
                <input type="password"
                disabled={currentlyLoading}
                 tabIndex={2}  autoComplete="current-password" required 
                className="form-control" id="password" name="password" />
            </label>
        </div>
        <input type="hidden" name="next" value={next} />
        {message_block}

        <div className="form-group">
            <input type="submit" 
            disabled={currentlyLoading}
            className="btn-primary btn" tabIndex={3} value="Anmelden" />
        </div>
        <div className="forgot-link">
            <Link tabIndex={5} to="/forgot-password">Passwort vergessen?</Link>
        </div>
    </Form>
}