// @ts-check
import React, { useEffect, useState } from "react"
import { Link, useLoaderData, useNavigate, useParams, useRevalidator } from "react-router-dom";
import FormGroup from "./FormGroup.js";

export async function SetNewPasswordLoader({request, params}){
    const res = await fetch(__API_URL__ + "/webapi/set-new-password-forgotten-token")
    const data = await res.json();

    const code_res = await fetch(__API_URL__ + "/webapi/test-confirm-forgotten-password/" + params.code)
    const code_data = await code_res.json()
    let return_email = "";
    let return_message = null;
    if (code_data.success){
        return_email = code_data.email;
    }
    else{
        return_message = code_data.error;
    }
    return {token: data.token, message: return_message, email: return_email};
}

export function SetNewPassword() {
    const revalidator = useRevalidator();

    const {token, message: inital_message, email} = useLoaderData();

    const navigate = useNavigate();
    const { code } = useParams();

    const [formData, setFormData] = useState({
        password1: "",
        password2: ""
    });

    const [message, setMessage] = useState(/** @type {string?} */(inital_message));

    const [newPasswordSuccess, setNewPasswordSuccess] = useState(false);

    useEffect(() => {
        if (newPasswordSuccess){
            navigate("/account", {replace: true});
        }
    }, [newPasswordSuccess]);

    function handleSubmit(ev){
        ev.preventDefault();

        setMessage(null);
        if (formData.password1 !== formData.password2){
            setMessage("Beide Passwörter stimmen nicht überein.");
        }
        else if (formData.password1.length < 8){
            setMessage("Ihr Passwort ist nicht lang genug.");
        }
        
        fetch(__API_URL__ + `/webapi/confirm-forgotten-password/${code}`, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                plainPassword: {
                    first: formData.password1,
                    second: formData.password2
                },
                _token: token
            })
        })
        .then(res => res.json())
        .then(data => {
            console.log("Password set successfully");
            if (data.success){
                if (revalidator.state === "idle") {
                    revalidator.revalidate();
                  }
                // We navigate in an effect, because the login status
                // change re-renders the component and interferes with the navigation. 
                setNewPasswordSuccess(true);
            }
            else{
                setMessage(data.error);
            }
        });
    }

    const handleInputChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
          ...formData,
          [name]: value
        });
      };

    let message_block = message !== null ? <div className="alert alert-danger">{message}</div> : null;

    return <>
            <p>Hier können Sie ein neues Passwort für Ihr telani-Account vergeben</p>
            <form name="user_changepw" method="post" onSubmit={handleSubmit}>
                {message_block}
                {email !== "" ? <>
                <FormGroup special_class="form-group" label_for="telani-account-email" label="telani-Account">
                    <input type="text" id="telani-account-email" readOnly value={email} />
                </FormGroup>
                <FormGroup label_for="user_changepw_plainPassword_first_react"
                    required={true}
                    label="Neues Passwort (min. 8 Zeichen)">
                    <input value={formData.password1} type="password" 
                        id="user_changepw_plainPassword_first_react" 
                        name="password1" onChange={handleInputChange} required className="form-control" />
                </FormGroup>
                <FormGroup label_for="user_changepw_plainPassword_second_react"
                    required={true} 
                    label="Neues Passwort (wiederholen)">
                    <input value={formData.password2} type="password" 
                    id="user_changepw_plainPassword_second_react" 
                    name="password2" onChange={handleInputChange} required className="form-control" />
                </FormGroup>
                <div className="clearfix form-group">
                    <input type="submit" value="Speichern" className="btn btn-primary pull-right" />
                </div>
                </>
                : 
                <p>
                    <Link to="/account">Anmelden</Link> - <Link to="/forgot-password">Passwort vergessen?</Link>
                </p>}
            </form>
        </>
}