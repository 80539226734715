// @ts-check
import React, {useState, useEffect} from "react"
import { GoToLogin } from "./react-entry.js";
import { useNavigate } from "react-router-dom";

export function GeneralAdministration({confirmCode, userId, userIsAdmin, userHasEmailConfimed, isOwnUser, onAdminUpdate}) {
    const navigate = useNavigate();

    const [deleteUserToken, setDeleteUserToken] = useState("");
    const [adminRightsToken, setAdminRightsToken] = useState("");

    useEffect(() => {
        fetch(__API_URL__ + '/webapi/deleteUserToken')
        .then(response => response.status === 401 ? GoToLogin() : response.json())
            .then(data => {
                setDeleteUserToken(data["token"]);
            })
    }, []);

    useEffect(() => {
        fetch(__API_URL__ + '/webapi/adminRightsToken')
        .then(response => response.status === 401 ? GoToLogin() : response.json())
            .then(data => {
                setAdminRightsToken(data["token"]);
            })
    }, []);

    function deleteClicked(ev){
        ev.preventDefault();
        fetch(__API_URL__ + `/webapi/users/${userId}?token=${deleteUserToken}`, {
            method: "DELETE",
            headers: {'Content-Type': 'application/json'},
        })
        .then(response => response.status === 401 ? GoToLogin() : response.json())
        .then(data => {
            if (data.success){
                navigate("/admin/users");
            }
        });
    }

    function makeAdmin(ev){
        ev.preventDefault();
        fetch(__API_URL__ + `/webapi/users/makeAdmin/${userId}/${adminRightsToken}`, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
        })
        .then(response => response.status === 401 ? GoToLogin() : response.json())
        .then(data => {
            if (data.success){
                onAdminUpdate();
            }
        });
    }

    function removeAdmin(ev){
        ev.preventDefault();
        fetch(__API_URL__ + `/webapi/users/deleteAdmin/${userId}/${adminRightsToken}`, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
        })
        .then(response => response.status === 401 ? GoToLogin() : response.json())
        .then(data => {
            if (data.success){
                onAdminUpdate();
            }
        });
    }

    function confirmMail(ev){
        ev.preventDefault();
        fetch(__API_URL__ + `/webapi/confirm-mail/${confirmCode}`, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
        })
        .then(response => response.status === 401 ? GoToLogin() : response.json())
        .then(data => {
            if (data.success){
                onAdminUpdate();
            }
            else{
                alert()
            }
        });
    }

    let confirmLink = null;
    if (confirmCode !== ""){
        confirmLink = <a href="#" onClick={confirmMail}>Manuell E-Mail bestätigen</a>;
    }
    else{
        confirmLink = <>Kein E-Mail Code</>;
    }
    
    return (
    <>
        {!userHasEmailConfimed ? 
            <p>{confirmLink}</p>
            : null }
        {!isOwnUser ?
            <p><a href="#" onClick={deleteClicked}>Konto löschen</a></p>
        : null }
        {!userIsAdmin ?
            <p><a href="#" onClick={makeAdmin}>Zum Administrator machen</a></p>
        :  
            <p><a href="#" onClick={removeAdmin}>Administratorrechte entfernen</a></p>
        }
    </>
    );
}