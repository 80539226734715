import React, { useEffect, useState } from "react";

export async function myPoolRemoveAction({params, request}){
    if (request.method !== "POST"){
        console.error("Wrong method");
    }
    console.log("REMOVING FROM POOL");

    const res = await fetch(__API_URL__ + "/webapi/remove-from-pool/" + params.targetUser, {
        method: "POST",
        signal: request.signal
    });
    const data = await res.json();
    
    return data;
}