// @ts-check
import React, { useState, useEffect} from "react";

/**
 * @typedef Props
 * @prop {React.ReactNode} children
 * @prop {string | null} [majorTitle]
 */

/**
 * @param {Props} props
 * @returns {React.ReactElement} A React element that renders a greeting to the user.
 */
export function MainWrapper({children, majorTitle = null}){
    return (
    <div className="wrapper style2">
        <section id="main" className="container narrowcontainer page-container">
            {majorTitle !== null ? 
                <header className="major">
                    <h2>{majorTitle}</h2>
                </header>
             : null }
            <section id="content" className="content">
                {children}
            </section>
        </section>
    </div>
    );
}