import React, { useEffect, useState } from "react";
import { useFetcher, Form, redirect } from "react-router-dom";

export async function declineInvitationAction({params, request}){
    if (request.method !== "POST"){
        console.error("Wrong method");
    }
    let formData = await request.formData();

    if (formData !== null && formData.has("report_invite")){

        console.log("REPORTING INVITATION")
        const res = await fetch(__API_URL__ + `/webapi/report-invitation/${params.invitation}/${params.invitationCode}`, {
            method: "POST",
            signal: request.signal
        });
        const data = await res.json();
        data.reported = true;
        if (data.success){
            return redirect("/invitation-declined?reported=true");
        } 
        else{
            return data;
        }
    }
    else{
        console.log("DECLINING INVITATION");

        const res = await fetch(__API_URL__ + `/webapi/decline-invitation/${params.invitation}/${params.invitationCode}`, {
            method: "POST",
            signal: request.signal
        });
        const data = await res.json();
        if (data.success){
            return redirect("/invitation-declined");
        } 
        else{
            return data;
        }
        
    }

    
}

const DeclineInvitation = ({invitation, invitationCode, email, onDecliningInvitation}) => {

    const fetcher = useFetcher();

    const [currentlyDecliningInvite, setCurrentlyDecliningInvite] = useState(false);


    useEffect(() => {
        if (currentlyDecliningInvite){
            console.log("currentlyDecliningInvite");
            onDecliningInvitation(true);
        }
    }, [currentlyDecliningInvite]);

    console.log(fetcher.data);

    return (!currentlyDecliningInvite ? 
        <button className="small alt invitationAccept" onClick={() => setCurrentlyDecliningInvite(true)}>Einladung ablehnen</button>
        :
    <>
    <a href="#" onClick={() => {onDecliningInvitation(false); setCurrentlyDecliningInvite(false)}}>Zurück</a>
    <p>Sind Sie sicher?</p>
    <fetcher.Form  action={"/decline-invitation/" + invitation + "/" + invitationCode} method="POST">
        <div className="checkbox">
    <label htmlFor="report_invite"> 
    <input type="checkbox"
        id="report_invite" 
        name="report_invite"
        value="1" />
        <span>
        {`Missbrauch melden: Diese Einladung wurde von ${email} an Sie gesendet. Bitte melden Sie Einladungen, die einen Verstoß gegen unsere AGB darstellen.`}
        </span>
    </label>
    <button className="alt small" type="submit">Einladung ablehnen</button>
</div>
    </fetcher.Form>
    </>);
    

    /*return (
    <fetcher.Form action={"/decline-invitation/" + invitation + "/" + invitationCode} method="POST">
       
    </fetcher.Form>);*/
}

export default DeclineInvitation;