// @ts-check

import React from "react";
import { Await, defer, json, redirect, useLoaderData } from "react-router-dom";
import { HeaderedSection } from "./HeaderedSection.js";
import { DateFormat } from "./DateFormat.js";
import { SentNotificationsList } from "./SentNotificationsList.js";
import { useTitle } from "./AccountRoot.js";
import { fetchUser } from "./APIHelpers/Users.js";

export async function advancedUserDetailsLoader({params, request}){
    const login_url = "/login?next=" + encodeURIComponent(request.url);
    let { userId } = params;

    let target_user = null;
    if (userId === undefined){
        try {
            target_user = await fetchUser(null, request.signal);
        }
        catch(error){
            if (error.message === "not logged in"){
                return redirect(login_url);
            }
        }
        userId = target_user?.id ?? 0;
    }
    else{
        try {
            target_user = await fetchUser(userId, request.signal);
        }
        catch(error){
            if (error.message === "not logged in"){
                return redirect(login_url);
            }
        }
    }

    const dataPromise = fetch(__API_URL__ + "/webapi/issued-licenses/" + userId, {
        signal: request.signal
    }).then(res => res.json());
    return defer({userId: userId, user: target_user, data: dataPromise});
}

export function AdvancedUserDetails(){
    useTitle("Erweiterte Konto-Details");
    const {userId, data: dataPromise, user} = useLoaderData();

    return <>
            <HeaderedSection title="Login in telani App">
                <p>{String.fromCodePoint(user.has_completed_install ? 0x2705 : 0x274C)} {user.has_completed_install ? "Nutzer hat telani heruntergeladen, telani gestartet, und in telani sich erfolgreich angemeldet."
                    : "Nutzer hat sich noch nicht in telani angemeldet."}</p>
            </HeaderedSection>
            <HeaderedSection title="Benachrichtigungen">
                <SentNotificationsList userId={userId} />
            </HeaderedSection>
            <HeaderedSection title="Ausgestellte Lizenzen">
            <React.Suspense fallback={<p>Laden...</p>}>
                <Await
                    resolve={dataPromise}
                    errorElement={
                        <p>Laden fehlgeschlagen.</p>
                    }>
                        {(data) => (
                        (data.licenses === undefined || data.licenses === null || data.licenses.length === 0) ?
                            <p>Noch keine Lizenzen ausgestellt.</p>
                            :
                <ul>
                {data.licenses.map((license) => {
                return <li key={license.id}><DateFormat value={new Date(license.date)} /></li>
            })}</ul>)}
                    </Await>
                </React.Suspense>
            </HeaderedSection>

    </>
}