// @ts-check
import React, { useState, useEffect } from "react"
import { HeaderedSection } from './HeaderedSection.js';
import { DateFormat } from "./DateFormat.js";
import { GoToLogin } from "./react-entry.js";
import Dropdown from "react-bootstrap/esm/Dropdown.js";
import Modal from 'react-bootstrap/esm/Modal.js';
import Button from 'react-bootstrap/esm/Button.js';
import Alert from 'react-bootstrap/esm/Alert.js';

const dateFormat = /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d/;

function ConfirmDeactivateModal({show, onHide, onDeactivate, isAdmin}) {
    return (
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Deaktivierung beantragen
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isAdmin ? "Deaktivieren dieses Geräts mit Admin-Rechten" : <><p>
            Wenn möglich verwenden Sie bitte die "Abmelden" Funktion in <strong>telani</strong>, dadurch wird das Gerät sofort deaktiviert.
        </p>
        <p>
            Bei einer Deaktivierung über die Webseite dauert es 4 Tage bis Sie telani auf einem anderen Gerät installieren können.
          </p></>}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={onHide}>Abbrechen</Button>
          <Button size="sm" variant="primary" onClick={() => onDeactivate(isAdmin)}>Deaktivierung beantragen</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  


function reviver(key, value) {
    if (typeof value === "string" && dateFormat.test(value)) {
      return new Date(value);
    }
  
    return value;
  }
  

export function DeviceManagement({userId, isAdmin})
{
    const [showConfirm, setShowConfirm] = useState("false");
    const [confirmDevice, setConfirmDevice] = useState(null);
    const handleConfirmClose = () => setShowConfirm("false");

    const [maximum_allowed_devices, setMaximumAllowedDevices] = useState(/** @type {(string|number)} */("?"));
    const [available_devices, setAvailableDevices] = useState(/** @type {(string|number)} */("?"));
    const [devices, setDevices] = useState(/** @type Array? */(null));
    const [refreshToken, setRefeshToken] = useState(0);

    useEffect(() => {
        fetch(__API_URL__ + `/webapi/activated-devices/${userId}`)
            .then(response => response.status === 401 ? GoToLogin() : response.text())
            .then(response => JSON.parse(response, reviver))
            .then(data => {
                setMaximumAllowedDevices(data.maximum_allowed_devices);
                setAvailableDevices(data.activated_devices);
                setDevices(data.devices);
        })}, [refreshToken]);

    function doDeactivate(ev, device, adminDeactivate = false){
        ev.preventDefault();
        setShowConfirm(adminDeactivate ? "admin" : "true");
        setConfirmDevice(device);
    }

    function doRealDeactivate(adminDeactivate = false){
        setShowConfirm("false");
        console.log("DoRealDeactivate", adminDeactivate);
        if (confirmDevice !== null){
            fetch(__API_URL__ + "/webapi/request-delete-activated-device/" + confirmDevice["id"],
            {
                method: "POST",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    isAdmin: adminDeactivate
                })
            })
                .then(response => response.json())
                .then(data => {
                    setRefeshToken(refreshToken + 1);
                });
        }
    }

    function cancelDeactivate(ev, device){
        setShowConfirm("false");
        if (device !== null){
            fetch(__API_URL__ + "/webapi/request-delete-activated-device/" + device["id"],
            {
                method: "DELETE"
            })
                .then(response => response.json())
                .then(data => {
                    setRefeshToken(refreshToken + 1);
                });
        }
    }

    var devicesBlock = null;
    if (devices !== null){
        devicesBlock = devices.map(element => 
            <li className={element["deactivation-requested"] !== null ? "device-deactivated device" : "device"} key={element["first-seen"]}>
                <div>
                    <img src="/img/LaptopArt.svg" className="laptopArt" />
                    <Dropdown>
                        <Dropdown.Toggle size="sm" variant="secondary" className="deviceActionDropDown">
                            Optionen
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {element["deactivation-requested"] === null && <Dropdown.Item onClick={(ev) => doDeactivate(ev, element)} href="#">Deaktivierung beantragen</Dropdown.Item>}
                            {element["deactivation-requested"] === null && isAdmin && <Dropdown.Item onClick={(ev) => doDeactivate(ev, element, true)} href="#">Admin-Deaktivierung</Dropdown.Item>}
                            {element["deactivation-requested"] !== null && <Dropdown.Item onClick={(ev) => cancelDeactivate(ev, element)} href="#">Deaktivierung abbrechen</Dropdown.Item>}
                        </Dropdown.Menu>
                    </Dropdown>                    
                    <h2>Windows-Gerät</h2>
                    
                    <p>
                        <span>Aktiviert: <DateFormat value={element["first-seen"]} /></span> | 
                        <span> Zuletzt verwendet: <DateFormat value={element["last-seen"]} /></span>
                    </p>
                    {element["deactivation-requested"] !== null && <Alert variant="secondary">Deaktivierung beantragt</Alert>}
                </div>
            </li>
        );
    }
    return (
        <HeaderedSection title="Aktivierte Geräte">
            {devices !== null && devices.length > 0 && 
            <ul>
            {devicesBlock}
            </ul>
            }
            <p>Aktuell sind {available_devices} von  {maximum_allowed_devices} {maximum_allowed_devices === 1 ? "Gerät" : "Geräten"} aktiviert.</p>
            <ConfirmDeactivateModal show={showConfirm !== "false"} onHide={handleConfirmClose} onDeactivate={doRealDeactivate} isAdmin={showConfirm === "admin"} />
        </HeaderedSection>
    );
}