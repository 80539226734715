// @ts-check
import '../node_modules/fslightbox/index.js';

export function InitializeLightbox(){
    let lightboxElements = document.querySelectorAll(".openInLightbox a");
    if (lightboxElements !== null){
        for (let i = 0; i < lightboxElements.length; i++){
            let elem = lightboxElements[i];
            if (elem instanceof HTMLElement){
                elem.dataset.fslightbox = "yes"
            }
        }
    }
    if (refreshFsLightbox !== undefined){
        refreshFsLightbox();
    }
}