// @ts-check
import React, { useEffect, useState } from "react";
import { Parser } from "html-to-react";
import { InitializeNavigation, UnInitializeNavigation } from "./navigation.js";

const Menu = () => {
    const [menuLoaded, setMenuLoaded] = useState(false);

    const [menuUserState, setMenuUserState] = useState({
        menuContent: "",
        isAdmin: false,
        isTrial: false,
        isViewer: false,
        daysValidity: 0,
        signedIn: false,
        logoutUrl: ""
    });

    useEffect(() => {
        if (menuLoaded){
            InitializeNavigation();
        }
        return () => UnInitializeNavigation();
    }, [menuLoaded]);

    useEffect(() => {
        updateMenu();
    }, []);

    function link(href, text, className = "", reload = false){
        return <a href={href} className={className}>{text}</a>
    }

    function updateMenu(){
        fetch(__API_URL__ + '/webapi/menuinfo')
            .then(response => response.json())
            .then(data => {
                setMenuUserState({
                    menuContent: decodeURIComponent(data.menu),
                    isAdmin: data.isAdmin,
                    isTrial: data.isTrial,
                    isViewer: data.isViewer,
                    daysValidity: data.daysValidity,
                    signedIn: data.signedIn,
                    logoutUrl: data.logoutUrl
                });
                setMenuLoaded(true);
            })
    }

    const parser = Parser(null);
    const markup = parser.parse(menuUserState.menuContent);

    if (!menuLoaded){
        return null;
    }

    return <>
    { menuUserState.isTrial &&
        <li>
            {link("/account/manage_subscription", "Test-Lizenz: Noch " + menuUserState.daysValidity + " " + (menuUserState.daysValidity != "1" ? "Tage" : "Tag"), "license-type-header trial-license-timer", false)}
        </li>
    }
    { menuUserState.isViewer &&
        <li>
            {link("/account/manage_subscription", "Jetzt telani Lizenz kaufen!", "license-type-header trial-license-timer", false)}
        </li>
    }

    { markup }

    { menuUserState.signedIn &&
    <li>{link("/account", "Konto", "", false)}</li>
    }
    { menuUserState.isAdmin &&
        <li>{link("/admin/users", "Admin", "", false)}</li>
    }
    { menuUserState.signedIn ?
        <li><a className="logoutLink" href={menuUserState.logoutUrl}>Abmelden</a></li>
    :
        <>
            <li>{link("/account", "Anmelden")}</li>
            <li className="calltoaction">{link("/register", "Kostenlos testen")}</li>
        </>
    }
    </>
}

export {Menu};