// @ts-check
import React, { useRef } from "react";
import { Form, json, redirect, useActionData, useFetcher, useLoaderData } from "react-router-dom";
import FormGroup from "./FormGroup.js";

export async function editLicenseAction({params, request}){
    if (request.method === "DELETE"){
        console.log("DELETE LICENSE");
        
        const res = await fetch(__API_URL__ + "/webapi/license/" + params.licenseId, {
            method: "DELETE",
            signal: request.signal
        });
        const data = await res.json();
        console.log("data", data)
        if (data.success){
            return redirect("/admin/users");
        } 
        else{
            return data;
        }

    } else if (request.method === "POST"){

        const formData = await request.formData();

        const res = fetch(__API_URL__ + "/webapi/license/" + params.licenseId, {
            signal: request.signal,
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                expiration: formData.get("expiration_date"),
                licensetype: formData.get("licenseType")
            })
        });
        return res;
    } else{
        console.error("Wrong method");
    }
}

export async function licenseDataLoader({params, request}){

    const res = await fetch(__API_URL__ + "/webapi/license/" + params.licenseId);
    const data = await res.json();

    return json({license: data});
}

export function EditLicense(){

    const deleteFetcher = useFetcher();

    const dateElement = useRef(null);
    const {license} = useLoaderData();

    let message = null;

    const actionData = useActionData();
    console.log(actionData);
    if (actionData !== undefined && actionData !== null){
        message = {isError: !actionData.success}
        if (actionData.message !== undefined && actionData.message !== null){
            message.message = actionData.message;
        }
        else if (actionData.success){
            message.message = "Erfolgreich gespeichert.";
        }
    }
    console.log(message);

    if (deleteFetcher.data !== undefined && deleteFetcher.data !== null){
        message = {isError: !deleteFetcher.data.success, message: deleteFetcher.data.message};
    }
    console.log("message", message, deleteFetcher.data);

    const clearDate = () => {
        if (dateElement.current !== null){
            dateElement.current.value = null;
        }
    }
    

    const message_block = message !== null ? <div className={`alert ${message.isError ? "alert-danger" : "alert-success"}`}>{message.message}</div> : null;

    const expirationDefaultValue = license.expiration !== null ? license.expiration.substring(0, 10) : "";

    return (<>
        <h4>Admin: Lizenz bearbeiten</h4>

        <p>Lizenz: {license.id}</p>
        <p>Nutzer: {license.user}</p>
        <p>Eigentümer: {license.owner}</p>

        <Form method="POST">
            <FormGroup label_for="licenseType" label="Lizenz">
                <select required id="licenseType" name="licenseType" defaultValue={license.licenseType} className="form-control">
                    <option value="1">Test</option>
                    <option value="2">Keine</option>
                    <option value="3">Lizenz</option>
                    <option value="6">telani Next</option>
                    <option value="8">telani Insight</option>
                    <option value="9">telani onSite</option>
                    <option value="10">Concept</option>
                </select>
            </FormGroup>
            <FormGroup label_for="expiration_date" label="Ablaufdatum">
                <input type="date" ref={dateElement} id="expiration_date" defaultValue={expirationDefaultValue} name="expiration_date" className="form-control"  />
                <button type="button" onClick={clearDate} className="right">Datum löschen</button>
            </FormGroup>
            {message_block}
            <input type="submit" value="Speichern" />
        </Form>


        <deleteFetcher.Form method="DELETE">
            <button className="alt small" type="submit">Lizenz löschen</button>
        </deleteFetcher.Form>
    </>
    )
}