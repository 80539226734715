// @ts-check
import React from "react";
import { Link } from "react-router-dom";

export function RegisterSuccess(){
    return (<>
        <p>Vielen Dank für die Registrierung. Wir haben Ihnen eine E-Mail gesendet, um die Adresse zu bestätigen.</p>
        <p>Bitte klicken Sie auf den Link in der E-Mail.</p>

        <Link to="/account">Zur Anmeldung</Link>
        </>
    );
}
