// @ts-check
import React, { useState, useRef} from "react";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Form, redirect, useActionData, useLoaderData, useNavigation } from "react-router-dom";

export async function forgotPasswordAction({request}){
    if (request.method !== "POST"){
        console.error("Wrong method");
    }
    const formData = await request.formData();

    const captchaResponse = formData.get("captchaResponse");
    const email = formData.get("email");
    const token = formData.get("token");

    if (captchaResponse !== "" && email !== ""){
        const response = await fetch(__API_URL__ + "/webapi/forgot-password", {
            method: "post",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                email: email,
                hCaptchaResponse: captchaResponse,
                _token: token
            })
        });

        const data = await response.json();

        if (data.success){
            return {isError: false, message: "Wir haben Ihnen eine E-Mail gesendet mit Anweisungen um ein neues Passwort zu vergeben."};
        }
        else{
            return {isError: true, message: data.error};
        }
    }
    else{
        return {isError: true, message: "Bitte E-Mail und Sicherheitsabfrage ausfüllen."};
    }
}

export async function forgotPasswordLoader({request}){
    const login_url = "/login?next=" + encodeURIComponent(request.url);
    const response = await fetch(__API_URL__ + '/webapi/forgotten-password-token',{
        signal: request.signal
    });
    if (response.status === 401){
        return redirect(login_url);
    }
    const data = await response.json();
    return {token: data["token"], siteKey: data["captcha-site-key"]};
}

/**
 * @typedef Message
 * @property {boolean} isError
 * @property {string?} message
 */

export function ForgotPassword(){
    const captchaRef = useRef(null);
    const emailRef = useRef(null);

    const {token, siteKey} = useLoaderData();

    const actionData = useActionData();

    const [captchaResponse, setCaptchaResponse] = useState("");
    let message = null;

    if (actionData !== undefined){
        // Clear email in Form.
        if (actionData.isError === false){
            if (captchaRef.current !== null){
                captchaRef.current.resetCaptcha();
            }
            if (emailRef.current !== null){
                emailRef.current.value = "";
            }
        }
        message = actionData;
    }

    const {state: loadingState} = useNavigation();

    const currentlyLoading = loadingState === "submitting" || loadingState === "loading";
    if (currentlyLoading){
        message = null;
    }

    function handleVerificationSuccess(token, ekey){
        setCaptchaResponse(token);
    }

    const onExpire = () => {
        console.log("hCaptcha Token Expired");
      };
    
    const onError = (err) => {
        console.log(`hCaptcha Error: ${err}`);
      };    

    let message_block = message !== null ? <div className={`alert ${message.isError ? "alert-danger" : "alert-success"}`}>{message.message}</div> : null;

    return <>
        <p>Bitte geben Sie Ihre E-Mail Adresse ein, dann senden wir Ihnen eine E-Mail 
        mit Anweisungen zum Wiederherstellen Ihres Passworts zu.</p>

        <Form name="forgotten_password" method="post">
            <input type="hidden" name="token" value={token} />
            <input type="hidden" name="captchaResponse" value={captchaResponse} />
            <div>
                <label htmlFor="forgotten_password_email" className="required">
                    E-Mail
                    <input autoComplete="email" type="email" ref={emailRef} disabled={currentlyLoading}
                    id="forgotten_password_email" name="email" required className="form-control" />
                </label>
            </div>
            
            <div>    
                <span className="fake-label">Sind Sie ein Roboter?</span>
                <HCaptcha sitekey={siteKey} 
                    ref={captchaRef}
                    onError={onError}
                    onExpire={onExpire}
                    onVerify={(token,ekey) => handleVerificationSuccess(token, ekey)} />        
            </div>
            { message_block }
            <div className="clearfix form-group">
                <input type="submit" value="Senden" disabled={currentlyLoading} className="btn btn-primary pull-right" />
            </div>
        </Form>
        </>
}