// @ts-check
import React, { useEffect, useState} from "react";
import { GoToLogin } from "./react-entry.js";

export function ActiveFeaturesBlock({userId}){

    const [features, setFeatures] = useState(/** @type {{enabled: Boolean, description: String, name: String}[] | null} */(null));   
    const [token, setToken] = useState("");    

    useEffect(() => {
        fetch(__API_URL__ + '/webapi/featuresToken')
            .then(response => response.status === 401 ? GoToLogin() : response.json())
            .then(data => {
                setToken(data["token"]);
            })
    }, []);

    useEffect(() => {
        updateFeatures();
    }, [userId]);

    function updateFeatures(){
        fetch(__API_URL__ + `/webapi/features/${userId}`)
        .then(response => response.status === 401 ? GoToLogin() : response.json())
            .then(data => {
                let parsed_features = data.features;
                setFeatures(parsed_features);
            });
    }

    function linkClicked(e){
        e.preventDefault();
        fetch(e.target.href)
            .then(() => updateFeatures());
    }

    if (features === undefined || features === null){
        return null;
    }

    let featuresElems = features.map(elem =>
        {
            let link;
            if (elem.enabled){
                link = <>[Aktiviert] <a href={`/webapi/disableFeature/${elem.name}/${userId}?token=${encodeURIComponent(token)}`} onClick={linkClicked}>ändern</a></>;
            }
            else {
                link = <>[Deaktiviert] <a href={`/webapi/enableFeature/${elem.name}/${userId}?token=${encodeURIComponent(token)}`} onClick={linkClicked}>ändern</a></>;
            }

            return <FeatureBlock enabled={elem.enabled} key={elem.name} name={elem.name} description={elem.description}>{link}</FeatureBlock>;
        });
    return <ul>{featuresElems}</ul>;
}

const FeatureBlock = ({description, name, children, enabled}) => {
    return <li>
        <strong>{String.fromCodePoint(enabled ? 0x2705 : 0x274C)}{name}</strong> {children}
        <div className="smallNote">{description}</div>
    </li>
}