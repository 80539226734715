// @ts-check
import React, { useState, useEffect} from "react";

export function RegisterAdvantages(){
    return (
    <section>    
        <h4 className="wp-block-heading">Testen Sie <strong>telani</strong> 7 Tage lang mit voller Funktionalität!</h4>
        <ul className="checklist wp-block-list">
            <li>Keine Kreditkarte notwendig</li>
            <li>Test endet nach 7 Tagen automatisch ohne Kündigung</li>
            <li>Volle Funktionalität</li>
            <li>Alle Module freigeschaltet</li>
        </ul>
        <div className="wp-block-group option-feature-description has-global-padding is-layout-constrained wp-block-group-is-layout-constrained">
            <h4 className="wp-block-heading">Was ist enthalten?</h4>
            <ul className="checklist wp-block-list">
                <li>Intuitive Anwendung zur Erstellung und Export von Brandfallsteuermatrizen</li>
                <li>Aktuellste Programmversion</li>
                <li>Keine Projektdaten in der Cloud</li>
                <li>Für alle Ihre Projekte</li>
            </ul>
        </div>
    </section>
    );
}
