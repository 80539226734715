// @ts-check
import React, { memo, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Parser } from "html-to-react";
import { InitializeNavigation, UnInitializeNavigation } from "./navigation.js";

const MenuRouted = ({menuData: menuUserState}) => {
    useEffect(() => {
        InitializeNavigation();
        return () => UnInitializeNavigation();
    }, []);

    function link(href, text, className = "", reload = false){
            return <NavLink to={href} className={className} {... (reload ? {reloadDocument: true} : {})}>{text}</NavLink>
    }

    const parser = Parser(null);
    const markup = parser.parse(menuUserState.menuContent);

    return <>
    { menuUserState.isTrial &&
        <li>
            {link("/account/manage_subscription", "Test-Lizenz: Noch " + menuUserState.daysValidity + " " + (menuUserState.daysValidity != "1" ? "Tage" : "Tag"), "license-type-header trial-license-timer", false)}
        </li>
    }
    { menuUserState.isViewer &&
        <li>
            {link("/account/manage_subscription", "Jetzt telani Lizenz kaufen!", "license-type-header trial-license-timer", false)}
        </li>
    }

    { markup }

    { menuUserState.signedIn &&
    <li>{link("/account", "Konto", "", false)}</li>
    }
    { menuUserState.isAdmin &&
        <li>{link("/admin/users", "Admin", "", false)}</li>
    }
    { menuUserState.signedIn ?
        <li><a className="logoutLink" href={menuUserState.logoutUrl}>Abmelden</a></li>
    :
        <>
            <li>{link("/account", "Anmelden")}</li>
            <li className="calltoaction">{link("/register", "Kostenlos testen")}</li>
        </>
    }
    </>
}

export {MenuRouted};