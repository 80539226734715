// @ts-check
import React, { useEffect, useState } from "react";
import { GoToLogin } from "./react-entry.js";

export function SentNotificationsList({userId}) {

    const [notifications, setNotifications] = useState(/** @type {Array?} */(null));

    useEffect(() => {
        fetch(__API_URL__ + `/webapi/sentNotifications/${userId}`)
            .then(response => response.status === 401 ? GoToLogin() : response.json())
            .then(data => {
                setNotifications(data["notifications"]);
            })
    }, [userId]);

    if (notifications === null || notifications.length === 0){
        return <p>Noch keine Nachrichten gesendet</p>;
    }

    let sentNoti = notifications.map(elem =>
        <li key={elem}>{elem}</li>
        );

    return (<ul>{sentNoti}</ul>);
}