// @ts-check
import React from "react";
import { MainWrapper } from "./MainWrapper.js";
  

export function LoginRoot({title, customClass="register", children}){
  return (
    <MainWrapper majorTitle={title}>
        <section className={customClass}>
            <article className="centeredsmall">
                {children}
            </article>
        </section>
    </MainWrapper>
  )
}