// @ts-check
import React from "react"

export function DateFormat({value}){
    let amount = value;
    if (typeof amount === 'string'){
        amount = parseFloat(amount);
    }
    let formatednumber = amount.toLocaleDateString('de-DE', {"dateStyle": "short"});
    return formatednumber;
}