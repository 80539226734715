// @ts-check
import React, { useEffect, useState } from "react"
import { AppInstallerParser } from "./AppInstallerParser.js";
import { useTitle } from "./AdminRoot.js";
import { json, redirect, useLoaderData } from "react-router-dom";

export async function telaniNextDataLoader({ request }){
    const login_url = "/login?next=" + encodeURIComponent(request.url);
    const res = await fetch(__API_URL__ + "/webapi/next-install-url", {
        signal: request.signal,
    });
    if (res.status === 401){

        return redirect(login_url);
    }
    const the_json = await res.json();
    const downloadURL = the_json.url;

    let version = null;
    if (downloadURL !== null){
        const parser = new AppInstallerParser(downloadURL + "telani-nightly.appinstaller");
        version = await parser.loadVersion();
    }
    return json({ downloadURL, version });
}

export function TelaniNextDownload()
{
    var {downloadURL, version} = useLoaderData();
    useTitle("Telani Nightly");

    return (
        <div>
            {version && <p>Die aktuelle telani Nightly Version ist: {version}</p>}
            <div className="wp-block-button primary">
                <a
                className="wp-block-button__link has-white-color has-text-color wp-element-button"
                href={downloadURL + "telani-nightly.appinstaller"}>Jetzt herunterladen!</a>
            </div>
        </div>
    );

}