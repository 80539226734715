// @ts-check
import React from "react";


export function ProductBox(){

    let productBoxImage = "/img/product-box.svg?v38";
    
    return (
        <div className="product-info-box info-box">
            <img className="float-left" src={productBoxImage} />
            <h3>telani Next Jahres-Abo</h3>
            <div className="price-label">Preis auf Anfrage</div>
            <div className="clearfix"></div>
        </div>);
}