import React from "react";
import { useSearchParams } from "react-router-dom";

export function InvitationDeclined(){

    const [searchParams, setSearchParams] = useSearchParams();

    const reported = searchParams.has("reported");

    let message_block = null;
    if (reported){
        message_block = <div className="alert alert-success">Wir werden den Vorfall untersuchen, vielen Dank für Ihre Mithilfe.</div>
    }
    return <><p>Einladung wurde abgelehnt.</p> {message_block}</>;
}