// @ts-check
import React from "react"


export function TermsAndPrivacy({currentlyLoading = false}) 
{
    return (<>
        <div className="form-group"><div>
            <div className="checkbox">
            <label className="required" htmlFor="user_register_privacy_policy_accepted"> 
            <input type="checkbox" disabled={currentlyLoading}
                id="user_register_privacy_policy_accepted" name="user_register[privacy_policy_accepted]" required={true}
                 value="1"             />
                <span>
                Ich habe die <a tabIndex={79}  href='/datenschutz'>Datenschutzerklärung</a> gelesen.
                </span>
            </label>
        </div>
    </div></div>
        <div className="form-group"><div>
        <div className="checkbox">
            <label className="required" htmlFor="user_register_terms_accepted"> 
            <input type="checkbox" disabled={currentlyLoading}
                id="user_register_terms_accepted" name="user_register[terms_accepted]" required={true}
                 value="1"             />
                <span>
                Ich habe die <a tabIndex={80} href='/bedingungen'>Allgemeinen Geschäftsbedingungen</a> gelesen und akzeptiere diese.
                </span>
            </label>
        </div>
    </div></div>
    </>);
}