// @ts-check
import React, { useState, useEffect} from "react";
import { Navigate, useNavigate, useParams, useRevalidator } from "react-router-dom";

export function ConfirmMail(){
    const revalidator = useRevalidator();
    const { code } = useParams();
    const navigate = useNavigate();
    const [message, setMessage] = useState(/** @type {string?} */(null));
    const [success, setSuccess] = useState(false);
    
    useEffect(() => {
        if (success){
            navigate("/account", {replace: true});
        }
    }, [success]);
    


    function handleSubmit(ev){

        ev.preventDefault();
        setMessage(null);
        fetch(__API_URL__ + `/webapi/confirm-mail/${code}`, {
            method: "POST",
        })
            .then(response => response.json())
            .then(data => {
                if (data.success){
                    if (revalidator.state === "idle") {
                        revalidator.revalidate();
                    }
                    setSuccess(true);
                }
                else if(data.error !== undefined){
                    setMessage(data.error);
                }
                else{
                    setMessage("Es ist ein Fehler aufgetreten.");
                }
            });
    }

    let message_block = message !== null ? <div className="alert alert-danger">{message}</div> : null;

    return <>
                <p>Klicken Sie diesen Button um die Registrierung abzuschließen:</p>
                <form method="POST" onSubmit={handleSubmit}>
                    <div className="clearfix form-group">
                        {message_block}
                        <input type="submit" value="E-Mail bestätigen" className="btn btn-primary pull-right" />
                    </div>
                </form>        
        </>
}
