import React, { useRef, useState } from "react";
import { json, redirect, useFetcher } from "react-router-dom";
import { LicenseTypeToString } from "./MyPool.js";
import Modal from 'react-bootstrap/esm/Modal.js';
import Button from 'react-bootstrap/esm/Button.js';

function CancelInviteConfirmation({show, onHide, onRevoke}){
    return (
        <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Einladung zurückziehen
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
            Sind Sie sicher, dass Sie die Einladung an diesen Nutzer zurückziehen möchten?
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={onHide}>Abbrechen</Button>
            <Button size="sm" variant="primary" onClick={onRevoke}>Einladung zurückziehen</Button>
        </Modal.Footer>
        </Modal>
    );
}

export function Invitation({id, email, licenseType, created}){
    const [showConfirm, setShowConfirm] = useState(false);

    const fetcher_invites = useFetcher({key: "revoke-invite-" + id});

    const sendRevoke = () => {
        fetcher_invites.submit({
            invitation: id,
        }, {
            method: "POST",
            action: "/account/revoke-invite/" + id
        })
        setShowConfirm(false);       
    };

    return  <div className="licenseItem">
        <strong>{email}</strong>
        <div>Eingeladen am: {created?.toLocaleDateString()}</div>
        <p>Lizenz: {LicenseTypeToString(licenseType)}</p>
        <button type="button" className="small button alt csvexportbutton" onClick={() => setShowConfirm(true)}>Einladung zurückziehen</button>
        <CancelInviteConfirmation show={showConfirm} onHide={() => setShowConfirm(false)} onRevoke={sendRevoke} />
    </div>;
}