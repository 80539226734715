// @ts-check
import React, { useEffect, useState } from "react"
import { useTitle } from "./AccountRoot.js";
import { Form, json, redirect, useActionData, useLoaderData, useNavigate, useParams } from 'react-router-dom';

export async function changePasswordAction({request, params}){
    let { userId } = params;
    if (userId === undefined){
        userId = null;
    }
    
    if (request.method !== "POST"){
        console.error("Wrong method");
        return {isError: true, message: "Das hat nicht funktioniert."};
    }
    const formData = await request.formData();
    const token = formData.get("user_changepw_token");
    if (formData.get("newPassword1") !== formData.get("newPassword2")){
        return {isError: true, message: "Die neuen Passwörter stimmen nicht überein."};
    }
    if (formData.get("newPassword1").length < 8){
        return {isError: true, message: "Ihr neues Passwort muss mindestens 8 Zeichen lang sein."};
    }

    let url = "/webapi/changePw";
    if (userId !== null){
        url += `/${userId === null ? "" : userId}`
    }
    try{
        const response = await fetch(__API_URL__ + url, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            signal: request.signal,
            body: JSON.stringify({
                oldPassword: formData.get("currentPassword"),
                plainPassword: { "first": formData.get("newPassword1"),
                    "second": formData.get("newPassword2")},
                _token: token
            })
        })
        const data = await response.json();
        if (!data.success){
            return {isError: true, message: data.error};
        }
        else{
            return {isError: false, message: "Passwort erfolgreich geändert."};
        }
    }
    catch(error){
        console.error('Error:', error);
        return {isError: true, message: "Bei der Verarbeitung ist ein Fehler aufgetreten."};
    }
}

export async function changePasswordLoader({params, request}){
    const login_url = "/login?next=" + encodeURIComponent(request.url);
    const dataPromise = fetch(__API_URL__ + `/webapi/userinfo${params.userId === null || params.userId === undefined ? "" : "/" + params.userId}`,{
        signal: request.signal
    }).then(result => {
        if (result.status === 401){
            return redirect(login_url);
        }
        return result.json()
    });
    const token_res_promise = fetch(__API_URL__ + '/webapi/changePwToken',{
        signal: request.signal
    }).then(token_res => {
        if (token_res.status === 401){
            return redirect(login_url);
        }
        return token_res.json();
    });    
    const currentUser_promise = fetch(__API_URL__ + '/webapi/userinfo',{
        signal: request.signal
    }).then(currentUser_res => {
        if (currentUser_res.status === 401){
            return redirect(login_url);
        }
        return currentUser_res.json();
    });

    const data = await dataPromise; 
    const token_data = await token_res_promise;
    const user_data = await currentUser_promise;

    let roles = user_data.roles ?? [];
    if (!Array.isArray(roles))
    {
        roles = Object.values(roles);
    }
    return json({ isLimitedUser: data["limitedUser"], token: token_data["token"], currentUserIsAdmin: roles.includes("ROLE_ADMIN"), currentUserEmail: data["email"]});
}

/**
 * @typedef Message
 * @property {boolean} isError
 * @property {string} message
 */

export function ChangePassword() {
    let { userId } = useParams();
    if (userId === undefined){
        userId = null;
    }

    const [formData, setFormData] = useState({
        currentPassword: "",
        newPassword1: "",
        newPassword2: "",
      });

    let message = null;

    let actionData = useActionData();
    if (actionData !== undefined && actionData !== null){
        message = {isError: actionData.isError, message: actionData.message};
        console.log("action data", actionData);
        if (!actionData.isError 
            && (formData.newPassword1 !== "" || formData.newPassword2 !== "" || formData.currentPassword !== "")){
            setFormData({
                currentPassword: "",
                newPassword1: "",
                newPassword2: "",
              });
        }
    }

    const {isLimitedUser, token, currentUserIsAdmin, currentUserEmail} = useLoaderData();

    const isAdmin = currentUserIsAdmin;
    useTitle("Konto Passwort ändern");

    let message_block = message !== null ? <div className={`alert ${message.isError ? "alert-danger" : "alert-success"}`}>{message.message}</div> : null;
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        setFormData({
          ...formData,
          [name]: value
        });
      };

    return  <>{isLimitedUser 
            ? 
            <div>Das Passwort dieses Kontos kann nicht geändert werden.</div> 
            :
            <div role="tabpanel" className="tab-pane active" id="passwort">
                <Form name="user_changepw" method="post">
                    <div id="user_changepw">
                        {!isAdmin ? <div>    
                            <label htmlFor="user_changepw_oldPassword" className="required">
                                Aktuelles Passwort
                                <input
                                 value={formData.currentPassword} 
                                 onChange={handleInputChange}
                                 type="password" id="user_changepw_oldPassword" 
                                 name="currentPassword" 
                                 required className="form-control" />
                            </label>
                        </div> :
                        <>
                            {/* This hidden field makes the action easier. */}
                            <input type="hidden" name="currentPassword" value="123" />
                            <p>Admin: Sie sind können das Passwort dieses Benutzers ({currentUserEmail}) ändern, aber bitte sein Sie vorsichtig.</p>
                        </>
                        }
                        <div>    
                            <label htmlFor="user_changepw_plainPassword_first" className="required">
                                Neues Passwort (min. 8 Zeichen)
                                <input value={formData.newPassword1}
                                onChange={handleInputChange} 
                                type="password" 
                                id="user_changepw_plainPassword_first" 
                                name="newPassword1" 
                                required className="form-control" />
                            </label>
                        </div>
                        <div>    
                            <label htmlFor="user_changepw_plainPassword_second" className="required">
                                Neues Passwort (wiederholen)
                                <input 
                                value={formData.newPassword2}
                                onChange={handleInputChange} 
                                type="password" 
                                id="user_changepw_plainPassword_second" 
                                name="newPassword2"
                                required className="form-control" />
                            </label>
                        </div>
                        <input type="hidden" id="user_changepw__token" name="user_changepw_token" value={token} />
                    </div>
                    {message_block}
                    <div className="form-group">
                        <button type="submit" id="change_password_form_save" className="primary">
                            Speichern
                        </button>
                    </div>
                </Form>
            </div>
            }
            </>
}