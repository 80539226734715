// @ts-check
import React from "react"


export function HeaderedSection({ title, children}) 
{
    return (
        <>
            <h4>{title}</h4>
            {children}
        </>);
}